import React from 'react'
import styled from 'styled-components'
import { TwitterShareButton } from 'react-twitter-embed';

import { color, media } from '../../theme'

const Wrapper = styled.div`
  background-color: ${color.WHITE};
  border-bottom: 1px solid ${color.LIGHT_GRAY};
  display: flex;
  justify-content: center;
  padding: 24px 0 0;
  ${media.twtr`
    padding: ${({ tight }) => tight ? `
      32px 0 
    ` : `
      32px 0
    `};
  `}
`

const Container = styled.div`
  width: calc(100vw - 32px);
  max-width: ${({ tight }) => tight ? '38rem' : '48rem'};
  ${media.twtr`
    width: calc(100vw - 48px);
  `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  ${media.twtr`
    margin-bottom: 0.5rem;
  `}
`
const ShareContainer = styled.div`
  height: 28px;
  margin-left: 1rem;
  margin-top: 6px;
  ${media.twtr`
    margin-top: 0.75rem;
  `}
`

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  ${media.sm`
    font-size: 28px;
  `}
  ${media.twtr`
    font-size: 36px;
  `}
  ${({ capitalize }) => capitalize && `
    text-transform: capitalize;
  `}
`

const Content = styled.div`
  & p {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 1.4;
    color: ${color.STEEL};
    ${media.twtr`
      font-size: 16px;
    `}
  }
  & a, & a:visited {
    color: ${color.TWITTER};
    text-decoration: none;
  }
  & a:hover {
    color: ${color.TEAL};
  }
`;

const Footer = styled.div``

const IntroSection = ({ 
  twitterShareProps,
  heading,
  // blurb,
  type,
  footer,
  children,
  tight,
  ...props
}) => {

  return (
    <Wrapper {...props}>
      <Container tight={tight}>
        <Header>
          <Heading capitalize={type === 'tag'}>{heading}</Heading>
          {twitterShareProps && (
            <ShareContainer>
              <TwitterShareButton {...twitterShareProps} />
            </ShareContainer>
          )}
        </Header>
        <Content>
          {children}
        </Content>
        {footer && 
          <Footer>
            {footer}
          </Footer>
        }
      </Container>
    </Wrapper>
  )
}

export default IntroSection
