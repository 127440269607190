import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
// import { TwitterShareButton } from 'react-twitter-embed';

import { color } from "../theme"

import IntroSection from "../components/IntroSection";
import SEO from "../components/seo"
import Layout, { CenterPage } from "../components/Layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import HelpPost from "../components/HelpPost"

const HelpPostContainer = styled.div`
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${color.LIGHTER_GRAY};
  &:last-child {
    border-bottom: 0;
  }
`
// const components = {
//   planning: Planning,
//   'impulse control': ImpulseControl,
// };
// const tagDefs = {
//   planning: 'Have you ever seen a kid thoughtfully map out an approach? Make a list? Is he ever prepared?'
// }

const TagPage = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout bgColor="LIGHTEST_GRAY">
      <SEO title="Helpful Twitter Posts" />
      <Header bgColor="TEAL" />
      <IntroSection
        heading={pageContext.tag}
        type="tag"
      >
        <p>Helpful posts tagged with “{pageContext.tag}”</p>
      </IntroSection>
      <CenterPage>
        {
          edges.map(edge => {
            const { id, html, fields, frontmatter } = edge.node
            if (frontmatter.post_type === 'helpful-parenting-post') {
              return (
                <HelpPostContainer key={id}>
                  <HelpPost
                    post={frontmatter}
                    slug={fields.slug}
                    currentTag={pageContext.tag}
                    inList
                  >
                    {html}
                  </HelpPost>
                </HelpPostContainer>
              )
            }
            return null;
          })
        }
      </CenterPage>
      <Footer bgColor="BLACK" />
    </Layout>
  )
}

export default TagPage

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          html
          frontmatter {
            title
            post_type
            date(formatString: "MMMM D, YYYY")
            title
            post_type
            tags
            quote
            cite
            credit
            attribution
            tweet {
              id
              entities {
                urls {
                  expanded_url
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`